<template>
  <BaseForm @submit.prevent="submit" :validation="v$" hideMandatory>
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          label="stp.requestNewPassword.form.label.mail"
          placeholder="stp.requestNewPassword.form.placeholder.mail"
          v-model="formValues.email"
          :validation="v$.email"
          :maxLength="null"
          hideMandatory
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton
        type="submit"
        label="stp.requestNewPassword.form.label.btn"
        :loading="loading"
        fullWidth
        small
      />
    </template>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation.js";
import { reactive } from "vue";

export default {
  name: "RequestForm",

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    const form = reactive({
      email: null,
    });

    const rules = {
      email: ["required", "email"],
    };

    const { v$, formValues } = useFormValidation({ rules, formValues: form });

    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error || props.loading) return;
      emit("submit", formValues);
    };

    return { v$, formValues, submit };
  },
};
</script>
