<template>
  <BaseFrontdoor keepOut>
    <div class="flex justify-content-center my-8">
      <div class="shadow-3 border-round p-4 w-22rem">
        <div class="flex flex-wrap justify-content-between align-content-center">
          <BaseHeadline size="1" fake="5" class="text-primary" noMargin>
            {{ $t("stp.requestNewPassword.title") }}
          </BaseHeadline>
          <BaseButton
            iconLeft="pi pi-times"
            rounded
            text
            color="gc-color-danger"
            @click="goToStartPage"
          />
        </div>

        <BaseMessage v-if="showSuccess" severity="success" :closable="false">
          {{ $t("stp.requestNewPassword.message.success") }}
        </BaseMessage>

        <RequestForm v-else :loading="isLoading" @submit="callApi" class="mb-3" />

        <div class="text-center">
          <BaseText size="xs">
            <BaseLink :to="{ name: 'Login' }">
              {{ $t("stp.requestNewPassword.form.label.login") }}
            </BaseLink>
          </BaseText>
        </div>
      </div>
    </div>
  </BaseFrontdoor>
</template>

<script>
import RequestForm from "./partials/RequestForm.vue";
import useApi from "@use/useApi";
import { ref } from "vue";

export default {
  name: "Request new password",

  setup() {
    const showSuccess = ref(false);

    const { isLoading, callApi } = useApi({
      module: "usm",
      method: "requestNewPassword",
      onSuccess: () => {
        showSuccess.value = true;
      },
    });

    return { isLoading, callApi, showSuccess };
  },

  components: {
    RequestForm,
  },

  methods: {
    async goToStartPage() {
      try {
        await this.$router.push({ name: "StartPage" });
      } catch {
        return;
      }
    },
  },
};
</script>
